const moment = require("moment");
const _ = require("lodash");

export const events = {
  "added-delegate": ({ fqdn = "site", delegate = "--" }) => {
    return {
      label: `Added delegate`,
      text: `Added delegate (${delegate}) to ${fqdn}`,
      type: `info`,
      filter: false
    };
  },
  "cancelled-service": () => {
    return {
      label: `Contract cancelled`,
      text: `Contract cancelled`,
      type: `info`,
      filter: true
    };
  },
  "cancelled-contract": () => {
    return {
      label: `Contract cancelled`,
      text: `Contract cancelled`,
      type: `error`,
      filter: true
    };
  },
  "contract-upgraded": () => {
    return {
      label: `Contract upgraded`,
      text: `Contract upgraded`,
      type: `success`,
      filter: true
    };
  },
  "contract-quota-reset": () => {
    return {
      label: `Contract quota reset`,
      text: `Contract quota reset`,
      type: `info`,
      filter: true
    };
  },
  "contract-renew-failed": () => {
    return {
      label: `Contract auto-renew failed`,
      text: `Contract auto-renew failed`,
      type: `error`,
      filter: true
    };
  },
  "contract-updated": () => {
    return {
      label: `Contract updated`,
      text: `Contract updated`,
      type: `info`,
      filter: true
    };
  },
  "contract-payment-method-linked": () => {
    return {
      label: `Payment method linked to contract`,
      text: `Payment method linked to contract`,
      type: `info`,
      filter: true
    };
  },
  "set-contract-price": () => {
    return {
      label: `Contract price updated`,
      text: `Contract price updated`,
      type: `info`,
      filter: true
    };
  },
  "changed-password": () => {
    return {
      label: `Password changed`,
      text: `Password changed`,
      type: `info`,
      filter: true
    };
  },
  "created-secret": () => {
    return {
      label: `Secret created`,
      text: `Secret created`,
      type: `info`,
      filter: true
    };
  },
  "created-site": () => {
    return {
      label: `Site created`,
      text: `Site created`,
      type: `info`,
      filter: true
    };
  },
  "credit-granted": ({ amount, currency = "" }) => {
    return {
      label: `Credit granted`,
      text: `${amount} ${currency.toUpperCase()} account credit granted`,
      type: `info`,
      filter: false
    };
  },
  "credit-quota-reset": () => {
    return {
      label: `Credit quota reset`,
      text: `Credit quota reset`,
      type: `info`,
      filter: true
    };
  },
  "cronned-alerts": () => {
    return {
      label: `Cronned alerts`,
      text: `Cronned alerts`,
      type: `cron`,
      filter: false
    };
  },
  "cronned-lapsed-contracts": () => {
    return {
      label: `Cronned lapsed contracts`,
      text: `Cronned lapsed contracts`,
      type: `cron`,
      filter: false
    };
  },
  "cronned-overdue-contracts": () => {
    return {
      label: `Cronned overdue contracts`,
      text: `Cronned overdue contracts`,
      type: `cron`,
      filter: false
    };
  },
  "cronned-contract-quotas": () => {
    return {
      label: `Cronned contract quotas`,
      text: `Cronned contract quotas`,
      type: `cron`,
      filter: false
    };
  },
  "cronned-client-replied-tasks": () => {
    return {
      label: `Cronned client replies`,
      text: `Cronned client replies`,
      type: `cron`,
      filter: false
    };
  },
  "cronned-scheduled-tasks": () => {
    return {
      label: `Cronned scheduled tasks`,
      text: `Cronned scheduled tasks`,
      type: `cron`,
      filter: false
    };
  },
  "cronned-sites-without-monitors": () => {
    return {
      label: `Cronned sites without monitors`,
      text: `Cronned sites without monitors`,
      type: `cron`,
      filter: false
    };
  },
  "cronned-inactive-tasks": () => {
    return {
      label: `Cronned inactive tasks`,
      text: `Cronned inactive tasks`,
      type: `cron`,
      filter: false
    };
  },
  "cronned-deleted-sites": () => {
    return {
      label: `Cronned deleted sites`,
      text: `Cronned deleted sites`,
      type: `cron`,
      filter: false
    };
  },
  "cronned-due-contracts": () => {
    return {
      label: `Cronned due contracts`,
      text: `Cronned due contracts`,
      type: `cron`,
      filter: false
    };
  },
  "cronned-in-progress-tasks": () => {
    return {
      label: `Cronned tasks 'In Progress'`,
      text: `Cronned tasks 'In Progress'`,
      type: `cron`,
      filter: false
    };
  },
  "custom-contract-created": () => {
    return {
      label: `Custom contract created`,
      text: `Custom contract created`,
      type: `success`,
      filter: true
    };
  },
  "database-backup-failed": ({ fqdn }) => {
    return {
      label: `Database backup failed`,
      text: `Database backup failed (${fqdn})`,
      type: `error`,
      filter: true
    };
  },
  "decrypted-secret": ({ secretLabel, userName, userRole }) => {
    return {
      label: `Secret decrypted`,
      text: `Secret '${secretLabel}' decrypted by ${userName} (${userRole})`,
      type: `info`,
      filter: true
    };
  },
  "deleted-card": ({ cardBrand, cardLast4 }) => {
    return {
      label: `Payment card deleted`,
      text: `Payment card deleted (${cardBrand} ending ${cardLast4})`,
      type: `info`,
      filter: true
    };
  },
  "deleted-secret": () => {
    return {
      label: `Secret deleted`,
      text: `Secret deleted`,
      type: `info`,
      filter: true
    };
  },
  "deleted-site": () => {
    return {
      label: `Site deleted`,
      text: `Site deleted`,
      type: `error`,
      filter: true
    };
  },
  "email-changed": ({ oldEmail = "?", newEmail = "?" }) => {
    return {
      label: `Email changed`,
      text: `Account email changed from '${oldEmail}' to '${newEmail}'`,
      type: `info`,
      filter: true
    };
  },
  "email-dispatched": ({ type }) => {
    return {
      label: `Email dispatched`,
      text: `Email dispatched (${type})`,
      type: `info`,
      filter: true
    };
  },
  "generated-monthly-site-report": () => {
    return {
      label: `Monthly report generated`,
      text: `Monthly report generated`,
      type: `info`,
      filter: true
    };
  },
  "invited-user": ({ email }) => {
    return {
      label: `User invited`,
      text: `User invited (${email})`,
      type: `info`,
      filter: true
    };
  },
  "lapsed-contract": () => {
    return {
      label: `Contract lapsed`,
      text: `Contract lapsed`,
      type: `error`,
      filter: true
    };
  },
  // eslint-disable-next-line no-empty-pattern
  "logged-in": ({}, { browser, ip }) => {
    return {
      label: `Login`,
      text: `Login on ${browser} (${ip})`,
      type: `secure`,
      filter: true
    };
  },
  // eslint-disable-next-line no-empty-pattern
  "logged-out": ({}, { browser, ip }) => {
    return {
      label: `Logout`,
      text: `Logout from ${browser} (${ip})`,
      type: `secure`,
      filter: true
    };
  },
  "malware-detected": () => {
    return {
      label: `Malware detected`,
      text: `Malware detected`,
      type: `error`,
      filter: true
    };
  },
  "manual-entry": ({ message }) => {
    return {
      label: `Manual entry`,
      text: `${message}`,
      type: `user`,
      filter: true
    };
  },
  "one-time-link-created": () => {
    return {
      label: `One-time link generated`,
      text: `One-time link generated`,
      type: `info`,
      filter: false
    };
  },
  "placed-order": () => {
    return {
      label: `New order`,
      text: `New order`,
      type: `success`,
      filter: true
    };
  },
  "reactivated-service": () => {
    return {
      label: `Contract reactivated `,
      text: `Contract reactivated`,
      type: `info`,
      filter: true
    };
  },
  "renewed-contract": () => {
    return {
      label: `Contract renewed`,
      text: `Contract renewed`,
      type: `success`,
      filter: true
    };
  },
  "restored-site": () => {
    return {
      label: `Site restored`,
      text: `Site restored`,
      type: `success`,
      filter: true
    };
  },
  "saved-card": ({ cardBrand, cardLast4 }) => {
    return {
      label: `Payment card added`,
      text: `Payment card added (${cardBrand} ending ${cardLast4})`,
      type: `info`,
      filter: true
    };
  },
  "site-backup-failed": ({ fqdn }) => {
    return {
      label: `Site backup failed`,
      text: `Site backup failed (${fqdn})`,
      type: `error`,
      filter: true
    };
  },
  "subscription-plan-changed": () => {
    return {
      label: `Subscription plan changed`,
      text: `Subscription plan changed`,
      type: `info`,
      filter: true
    };
  },
  "task-agent-added": () => {
    return {
      label: `Agent assigned to task`,
      text: `Agent assigned to task`,
      type: `info`,
      filter: false
    };
  },
  "task-closed": () => {
    return {
      label: `Task closed`,
      text: `Task closed`,
      type: `info`,
      filter: true
    };
  },
  "task-created": () => {
    return {
      label: `Task created`,
      text: `Task created`,
      type: `success`,
      filter: true
    };
  },
  "task-reopened": () => {
    return {
      label: `Task re-opened`,
      text: `Task re-opened`,
      type: `info`,
      filter: true
    };
  },
  "task-priority-changed": ({ priority }) => {
    return {
      label: `Task priority changed`,
      text: `Task priority changed to '${
        priority ? _.capitalize(priority.substr(2)) : ""
      }'`,
      type: `info`,
      filter: true
    };
  },
  "task-subject-changed": ({ subject }) => {
    return {
      label: `Task subject updated`,
      text: `Task subject changed to '${subject}'`,
      type: `info`,
      filter: true
    };
  },
  "task-participants-updated": () => {
    return {
      label: `Task participants updated`,
      text: `Task participants updated`,
      type: `info`,
      filter: true
    };
  },
  "trashed-site": () => {
    return {
      label: `Site trashed`,
      text: `Site trashed`,
      type: `trash`,
      filter: true
    };
  },
  "updated-secret": () => {
    return {
      label: `Secret updated`,
      text: `Secret updated`,
      type: `info`,
      filter: true
    };
  },
  "task-schedule-changed": ({ dateScheduled }) => {
    return {
      label: `Task scheduled`,
      text: `Task scheduled to ${moment(new Date(dateScheduled)).format(
        "Do MMM, YYYY @ HH:mm"
      )}`,
      type: `cron`,
      filter: true
    };
  },
  "made-reseller": ({ email }) => {
    return {
      label: `Made reseller`,
      text: `Reseller status granted for ${email}`,
      type: `info`,
      filter: true
    };
  },
  "reseller-client-created": ({ email }) => {
    return {
      label: `Client added`,
      text: `New client with email ${email} is added`,
      type: `success`,
      filter: false
    };
  },
  "user-enabled": () => {
    return {
      label: `Account activated`,
      text: `Account activated`,
      type: `info`,
      filter: false
    };
  },
  "user-disabled": () => {
    return {
      label: `Account disabled`,
      text: `Account disabled`,
      type: `info`,
      filter: false
    };
  },
  "removed-delegate": ({ fqdn = "site", delegate = "--" }) => {
    return {
      label: `Removed delegate`,
      text: `Removed delegate (${delegate}) from ${fqdn}`,
      type: `info`,
      filter: false
    };
  },
  "reseller-reset-password-email": () => {
    return {
      label: `Password reset email sent`,
      text: `Password reset  email sent`,
      type: `secure`,
      filter: false
    };
  },
  "reseller-reset-password": () => {
    return {
      label: `Password reset`,
      text: `Password reset`,
      type: `secure`,
      filter: false
    };
  },
  "transferred-contract": ({ oldFqdn, newFqdn }) => {
    return {
      label: `Contract transferred`,
      text: `Contract transferred from ${oldFqdn} to ${newFqdn}`,
      type: `info`,
      filter: false
    };
  },
  "contract-plan-changed": ({ oldProductTerm, newProductTerm }) => {
    return {
      label: `Contract plan changed`,
      text: `Contract plan changed from ${_.get(
        oldProductTerm,
        "name",
        ""
      )} to ${_.get(newProductTerm, "name", "")}`,
      type: `info`,
      filter: false
    };
  },
  "user-pin-generated": () => {
    return {
      label: `Support pin changed`,
      text: `Support pin changed`,
      type: `secure`,
      filter: true
    };
  },
  "passed-phone-verification": () => {
    return {
      label: `Passed phone verification`,
      text: `Passed phone verification`,
      type: `secure`,
      filter: true
    };
  },
  "wordpress-plugin-update-requested": ({ slug }) => {
    return {
      label: `Plugin update requested`,
      text: `Update to '${slug}' requested`,
      type: `info`,
      filter: true
    };
  },
  "wordpress-plugin-updated": ({ slug }) => {
    return {
      label: `Plugin updated`,
      text: `Plugin '${slug}' updated`,
      type: `success`,
      filter: true
    };
  },
  "wordpress-plugin-update-failed": ({ slug }) => {
    return {
      label: `Plugin update failed`,
      text: `Plugin '${slug}' failed to update`,
      type: `error`,
      filter: true
    };
  },
  "wordpress-theme-update-requested": ({ slug }) => {
    return {
      label: `Theme update requested`,
      text: `Update to '${slug}' requested`,
      type: `info`,
      filter: true
    };
  },
  "wordpress-theme-updated": ({ slug }) => {
    return {
      label: `Theme updated`,
      text: `Theme '${slug}' updated`,
      type: `success`,
      filter: true
    };
  },
  "wordpress-theme-update-failed": ({ slug }) => {
    return {
      label: `Theme update failed`,
      text: `Theme '${slug}' failed to update`,
      type: `error`,
      filter: true
    };
  },
  "fixed-vault-password-added": ({ userName }) => {
    return {
      label: `Password added`,
      text: `Password added to vault by ${userName}`,
      type: `success`,
      filter: true
    };
  },
  "fixed-vault-password-decrypted": ({ userName }) => {
    return {
      label: `Password decrypted`,
      text: `Password decrypted by ${userName}`,
      type: `info`,
      filter: true
    };
  },
  "fixed-vault-password-updated": ({ userName }) => {
    return {
      label: `Password updated`,
      text: `Password updated in the vault by ${userName}`,
      type: `info`,
      filter: true
    };
  },
  "fixed-vault-password-deleted": ({ userName }) => {
    return {
      label: `Password deleted`,
      text: `Password deleted from the vault by ${userName}`,
      type: `info`,
      filter: true
    };
  }
};
